






































































import Vue from "vue";
import data from "@/datatables/pos/paused-cashtray";
import { currency } from "@/utils/helpers";
import datatable from "@/utils/datatable/components/datatable.vue";
import {getCashtrayData , closeCashtray} from "@/repositores/cashtray"
export default Vue.extend({
  name: "pos-cashtray",
  components: {
    datatable,
  },
  data() {
    return {
      currentAmount: null,
      income: {
        initialExchangeAmount: null,
        revenue: null,
        totalAmount: null,
        delivery: null,
        total: null,
      },
      outcome: {
        outcome: null,
        visa: null,
        endExchangeAmount: null,
        TotalVoidCash: null,
        discount: null,
        payLater: null,
        deliveryNoneReturn: null,
        total: null,
      },

      plus:0,
      minus:0,
      data,
    };
  },
  methods: {
    getCashtrayData(){
      getCashtrayData(parseInt(this.$route.params.serial)).then((resp:any) => {
        // incom

        this.income.initialExchangeAmount = resp['StartCash']
        this.income.revenue = resp['TotalIn']
        this.income.totalAmount = resp['TotalCash']
        this.income.delivery = resp['HomeOutCashTry']
        
        
        this.income.total = resp['StartCash'] + resp['TotalIn'] + resp['TotalCash'] + resp['HomeOutCashTry']




        // outcome
        this.outcome.outcome = resp['TotalOut']
        this.outcome.visa = resp['TotalVisa']
        this.outcome.endExchangeAmount = resp['EndCash']
        this.outcome.discount = resp['DiscValue']
        this.outcome.payLater = resp['PayLater']
        this.outcome.deliveryNoneReturn = resp['DeliveryNonReturn']
        this.outcome.TotalVoidCash = resp['TotalVoidCash']


        this.outcome.total = resp['TotalOut'] + resp['TotalVisa'] + resp['EndCash'] + resp['DiscValue']+ resp['PayLater'] + resp['DeliveryNonReturn'] + resp['TotalVoidCash']

        const calc = parseInt(this.$route.params.amount) - (this.income.total! - this.outcome.total! )
        calc > 0 ? this.plus = calc : this.minus = Math.abs(calc)
      })
    },
    currency,
    close() {
      closeCashtray(parseInt(this.$route.params.serial)).then(() => {
        this.$router.push({ name: "" });
      })
    },
  },

  created(){
    this.getCashtrayData()

  }
});
